<template>
    <div class="field"
         :class="{field_invalid: !field.valid && field.status}">

        <div class="field__wrapper">
            <transition name="opacity">
                <div v-if="!field.empty"
                     class="field__clear close"
                     @click="onClear"
                     title="Очистить поле"></div>
            </transition>

            <label v-if="field.label"
                   class="field__label"
                   :for="field.name">{{ field.label }} <span v-if="field.required">*</span></label>

            <textarea v-if="field.type==='textarea'" class="field__textarea"
                      :id="field.name"
                      autocomplete="off"
                      @blur="field.blur"
                      @input="onInput"
                      v-model="value"></textarea>

            <select v-else-if="field.type==='select'" class="field__select"
                    @blur="field.blur"
                    @input="inputHandler"
                    @change="changeHandler"
                    :name="field.label"
                    v-model="value">
                <option value="">--Выбрать способ получения--</option>
                <option v-for="item in field.items"
                        :value="item.value"
                        :key="item.id">{{ item.label }}
                </option>
            </select>

            <input v-else class="field__input"
                   :id="`${field.name}-${prefix}`"
                   :type="field.type"
                   :placeholder="field.placeholder"
                   :class="[{field_compact: compact}]"
                   autocomplete="off"
                   @blur="field.blur"
                   @focus="field.focus"
                   @input="inputHandler"
                   @change="changeHandler"
                   v-model="value">
        </div>

        <small v-if="field.help"
               class="field__help">{{ field.help }}</small>

        <transition name="fade">
            <v-errors v-if="field.status && !field.valid"
                      :errors="field.errors"
                      class="field__error"
            ></v-errors>
        </transition>
    </div>
</template>

<script>
import {ref} from 'vue'
import Errors from "@components/ui/forms/Errors";

export default {
    name: "Field",
    components: {
        "v-errors": Errors
    },
    props: {
        field: Object,
    },
    setup(props, {emit}) {
        const value = ref(props.field.value)

        function onClear() {
            value.value = ''
            // emit('update:value', '')
            emit('onChange', {value: '', item: props.field});
        }

        function inputHandler() {
            emit('onInput', {value: value.value, item: props.field});
        }

        function changeHandler() {
            emit('onChange', {value: value.value, item: props.field});
        }

        function onInput(e) {
            return emit('update:value', e.target.value.trim())
        }

        return {
            value,
            onInput,
            inputHandler,
            changeHandler,
            onClear,
        }
    },
}
</script>

<style lang="less">
.field {
    margin-bottom: 1rem;

    &__label {
        display: flex;
        margin-bottom: 10px;
        font-size: var(--label-size);
        color: var(--gray);

        span {
            font-size: var(--p);
            font-weight: bold;
            color: var(--primary);
        }
    }

    &__select,
    &__textarea,
    &__input {
        display: flex;
        height: 40px;
        width: 100%;

        font-size: 16px;
        padding: 5px 10px;
        box-sizing: border-box;

        border: var(--border-size) solid var(--border-color);
        outline: 0;

        &:focus {
            border: var(--border-size) solid var(--success);
        }
    }

    &__textarea {
        resize: none;
        min-height: 120px;
        width: 100%;
    }

    &__help {
        font-size: 11px;
        color: gray;
    }

    &__wrapper {
        position: relative;
    }

    &__clear {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 30px;
        height: 30px;

        position: absolute;
        top: auto;
        bottom: 5px;
        right: 5px;

        background: var(--secondary-dark);

        border-color: var(--secondary-dark);
        border-radius: var(--border-radius-sm);

        transition: background-color .3s ease-in-out;

        &:hover {
            background: var(--secondary-darker);
            border-color: var(--secondary-darker);
        }
    }

    &_invalid {
        .field {
            &__input {
                border: var(--border-size) solid var(--danger) !important;
            }
        }
    }

    &__errors {

    }

    input[type="date"],
    input[type="datetime-local"] {
        &::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: red;
            background: url('/static/img/icons/calendar-sm.svg') no-repeat;
            width: 21px;
            height: 28px;
            position: absolute;
            right: 4px;
            border-width: thin;
        }
    }

    select {
        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%2311c993' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 2rem !important;
    }
}
</style>
